.landingCard {
    .landing-cardimg {
        border-radius: 15px;
        margin-bottom: 16px;
        img {
            width: 100%;
            height: 100%;
        }
    }
    .landing-cardcontent {
        margin-bottom: 40px;
        &:last-child {
            margin-bottom: 0px;
        }
        .landingcard_content-2 {
            display: flex;
            // margin-top: 24px;
            gap: 12px;
            .l-inner-content {
                .landingcard-coheading {
                    font-family: $hellix;
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 25px;
                    color: #171717;
                }
                .landingcard_paragraph {
                    font-family: $hellix;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 25px;
                    color: #4a4a4a;
                    margin-bottom: 18px;
                }
            }
        }
    }
}

@media only screen and (min-width: 990px) {
    .landingCard {
        display: flex;
        align-items: center;
        gap: 30px;
        .landing-cardimg {
            width: 45%;
            height: 440px;
            margin: 0px;
        }
    }
    .landing-cardcontent {
        width: 50%;
    }
    .landingCard-reverse {
        flex-direction: row-reverse;
    }
}

@media only screen and (min-width: 1280px) {
    .landingCard {
        gap: 60px;
        margin-bottom: 60px;
        &:last-child {
            margin-bottom: 50px;
        }
    }
}
