.footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 16px;
    // margin: 40px 0px 0px 0px;
    background-color: #ffffff;
    margin-top: 48px;
    @media only screen and (min-width: 560px) {
        padding-bottom: 16px;
    }
    // .powered_by {
    //     display: flex;
    //     justify-content: center;
    //     align-items: center;
    //     margin-bottom: 8px;
    //     p {
    //         @include small-font;
    //         text-align: center;
    //         color: #211e27;
    //         margin: 0px 8px 0px 0px;
    //         padding: 0px;
    //     }
    // }
    .powered_by {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        text-align: center;
        p {
            text-align: center;
            color: #211e27;
            margin: 0px 8px 0px 0px;
            padding: 0px;
            font-size: 12px;
            line-height: 18px;
        }
    }
    .powered_by:before,
    .powered_by:after {
        content: "";
        display: inline-block;
        width: 85px;
        height: 0.5px;
        vertical-align: middle;
        background-color: #dadada;
    }

    .powered_by:before {
        margin-right: 10px;
    }
    .powered_by:after {
        margin-left: 10px;
    }
    .welcomepage__desc {
        font-weight: 400;
        font-size: 9px;
        font-family: $font-fam;
        line-height: 11px;
        text-align: center;
        color: #646268;
        margin-bottom: 4px;
        // margin-top: 16px;
    }
    img {
        object-fit: cover;
    }
    .learnmore {
        @include small-font;
        text-align: center;
        text-decoration-line: underline;
        color: #211e27;
        // margin-top: 16px;
    }
}
