.vmenu_plan {
    .landing_headingII,
    .package_content,
    .landingpackage_subheading,
    h4,
    p,
    .landingpackage_btn button,
    .starting_at {
        font-family: $fontfam-1;
    }
    .packagelist_content {
        div {
            &:nth-child(2) {
                font-family: $fontfam-1 !important;
            }
        }
    }
}
