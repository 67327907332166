.landing_wrapper {
    display: flex;

    .welcome-page {
        background-repeat: no-repeat;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 56px;
        width: 100%;
        @media only screen and (min-width: 768px) {
            width: 50%;
        }

        .cover__image {
            width: 100%;
            height: 260px;

            position: relative;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .logo-wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            top: calc(240px - 140px);
            width: 140px;
            height: 140px;
            padding: 4px;
            border-radius: 50%;
            background-color: #ffffff;
            position: absolute;
            img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
                object-fit: contain;
            }
        }
        .cover__image:after {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            display: inline-block;
            background: -moz-linear-gradient(
                0deg,
                rgba(33, 33, 33, 0),
                rgba(33, 33, 33, 0)
            );
            /* FF3.6+ */
            background: -webkit-gradient(
                linear,
                left top,
                left bottom,
                color-stop(0%, rgba(33, 33, 33, 0)),
                color-stop(100%, rgba(33, 33, 33, 0))
            );
            /* Chrome,Safari4+ */
            background: -webkit-linear-gradient(
                top,
                rgba(33, 33, 33, 0) 0%,
                rgba(33, 33, 33, 0) 100%
            );
            /* Chrome10+,Safari5.1+ */
            background: -o-linear-gradient(
                top,
                rgba(33, 33, 33, 0) 0%,
                rgba(33, 33, 33, 0) 100%
            );
            /* Opera 11.10+ */
            background: -ms-linear-gradient(
                top,
                rgba(33, 33, 33, 0) 0%,
                rgba(33, 33, 33, 0) 100%
            );
            /* IE10+ */
            background: linear-gradient(
                to bottom,
                rgba(33, 33, 33, 0) 0%,
                rgba(33, 33, 33, 0) 100%
            );
            /* W3C */
            /* IE6-9 */
        }
        .home__content {
            // width: 100%;
            background-color: #ffffff;
            padding: 4px 24px 0 24px;
            border-radius: 15px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin: auto;
            margin-top: -40px;
            width: 100%;
            z-index: 1;
            height: 100%;
            height: calc(100vh - 280px);
            .button_container {
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
            }
            .content-box {
                .top-content {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-bottom: 40px;
                    .table-num {
                        // font-family: $font-fam;
                        font-style: normal;
                        font-weight: 700;
                        font-size: 14px;
                        line-height: 18px;
                        text-align: center;
                        color: #000000;
                        text-transform: capitalize;
                    }
                    .table-btn {
                        text-align: center;
                        color: #211e27;
                        background: #f1f1f1;
                        border-radius: 87px;
                        padding: 6px 12px;
                    }
                }
                .front-content {
                    .front-btn {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        padding: 16px 0;
                        border-bottom: 0.524px solid #dadada;
                        &:last-child {
                            border: none;
                        }
                        span {
                            font-weight: 600;
                            font-size: 16px;
                            line-height: 19px;
                            color: #1d1d1d;
                            cursor: pointer;
                        }
                        .social-list {
                            height: 100vh;
                            width: 56px;
                            transition: 0.3s ease-in-out;
                        }
                        .table-btn {
                            @include small-font;
                            font-weight: 600;
                            text-align: center;
                            color: #211e27;
                            background: #f1f1f1;
                            border-radius: 87px;
                            padding: 6px 12px;
                            cursor: pointer;
                        }
                    }
                    .front-btn.touched {
                        transition: transform 0.2s;
                        &:active {
                            transform: scale(0.95);
                            transition: color 0.2s, font-weight 0.2s;
                            color: #212121;
                            font-weight: 700;
                            span {
                                font-weight: 800;
                            }
                        }
                    }
                }
                .content-btn {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    row-gap: 16px;
                    margin-bottom: 16px;
                    a {
                        width: 100%;
                    }
                    img {
                        width: 42px;
                        height: 12px;
                        color: #000000;
                        object-fit: cover;
                        margin-top: 4px;
                    }
                }
            }
        }
    }
    .rprofile_socialicons {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 12px;
        margin-bottom: 20px;
        margin-top: 4px;
    }
    .rprofile_socialicons img {
        cursor: pointer;
    }
    .socialicon-svg {
        width: 22px;
        height: 22px;
    }
    .socials-drawer {
        .ant-drawer-title {
            text-align: center;
        }
    }
    // .social-list {
    //     height: 0;
    //     width: 56px;
    //     transition: 0.3s ease-in-out;
    //     width: 100%;
    //     display: none;
    //     display: flex;
    //     flex-direction: column;
    //     opacity: 0;
    // }
    // .social-list.active {
    //     display: flex;
    //     min-height: 100px;
    //     height: 300px;
    //     transition: 0.3s ease-in-out;
    //     opacity: 1;
    // }

    .cover_container {
        height: calc(100vh - 50px);
        position: fixed;
        right: 0;
        top: 50px;
        display: none;
        @media only screen and (min-width: 768px) {
            display: block;
            width: 50%;
            img {
                width: 100%;
                height: 100%;
            }
        }
    }
}
.socials-drawer {
    .ant-drawer-title {
        text-align: center;
    }
    .ant-drawer-header-title {
        display: flex;
        flex-direction: row-reverse;
    }
    .ant-drawer-content {
        border-radius: 16px 16px 0 0px;
    }
    .ant-drawer-body {
        display: flex;
        flex-direction: column;
        gap: 12px;
        -ms-overflow-style: none;
        scrollbar-width: none;
        &::-webkit-scrollbar {
            display: none;
        }

        .social_button {
            text-decoration: none;
            border-radius: 8px;
            button {
                width: 100%;
                padding: 16px;
                border-radius: 8px;
                font-weight: 400;
                font-size: 13px;
                line-height: 140%;
                text-align: center;
                border: none;
                color: #ffffff;
                cursor: pointer;
            }
        }
        .fb_media {
            background-color: #1674ea;
        }
        .ig_media {
            background: #f09433;
            background: -moz-linear-gradient(
                45deg,
                #f09433 0%,
                #e6683c 25%,
                #dc2743 50%,
                #cc2366 75%,
                #bc1888 100%
            );
            background: -webkit-linear-gradient(
                45deg,
                #f09433 0%,
                #e6683c 25%,
                #dc2743 50%,
                #cc2366 75%,
                #bc1888 100%
            );
            background: linear-gradient(
                45deg,
                #f09433 0%,
                #e6683c 25%,
                #dc2743 50%,
                #cc2366 75%,
                #bc1888 100%
            );
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );
        }
        .tw_media {
            background-color: #00aced;
        }
        .tiktok_media {
            background-color: #000000;
        }
        .td_media {
            background-color: #00af87;
        }
    }
}
