.feedback_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(255, 255, 255);
    .feedback_container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-top: 56px;
        background-color: #ffffff;
        width: 100%;
        min-height: calc(100vh - 56px);
        padding: 30px 16px 16px 16px;
        border-radius: 8px;
        button {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 8px;
            @media only screen and (min-width: 560px) {
                position: absolute;
                bottom: 30px;
                left: 30px;
                width: calc(100% - 60px);
            }
        }
        @media only screen and (min-width: 560px) {
            padding: 30px;
        }
        h1 {
            font-weight: 600;
            font-size: 20.8333px;
            line-height: 25px;
            color: $primaryColor;
            margin-bottom: 24px;
        }
        .feedback_inputfield {
            margin-bottom: 16px;
            label {
                font-weight: 500;
                font-size: 16px;
                line-height: 21px;
                color: $primaryColor;
            }
            input {
                margin-top: 4px;
                width: 100%;
                border: 1px solid #e9e9e9;
                height: 46px;
                border-radius: 8px;
                color: $primaryColor;
                outline: none;
                padding: 8px;
                font-weight: 400;
                font-size: 16px;
                &::placeholder {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 21px;
                    color: #bcbcbe;
                }
            }
        }
        .feedback_descriptionfield {
            margin-bottom: 16px;
            label {
                font-weight: 500;
                font-size: 16px;
                line-height: 21px;
                color: $primaryColor;
            }
            .description_textarea {
                outline: none;
                margin-top: 4px;
                width: 100%;
                height: 140px;
                height: 140px;
                border-radius: 8px;
                border: 1px solid #e9e9e9;
                color: $primaryColor;
                font-weight: 400;
                font-size: 16px;
                line-height: 21px;
                padding: 8px;
                -ms-overflow-style: none;
                scrollbar-width: none;
                &::placeholder {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 21px;
                    color: #bcbcbe;
                }
                &::-webkit-scrollbar {
                    display: none;
                }
            }
            p {
                color: #f70000;
            }
        }
        .feedback_submit {
            width: 100%;
            height: 46px;
            button {
                width: 100%;
                height: 100%;
                border-radius: 8px;
                border: none;
                background-color: $primaryColor;
                color: #ffffff;
                font-weight: 600;
                font-size: 17.3218px;
                line-height: 21px;
                text-align: center;
                &:active {
                    background-color: $primaryColor;
                    color: $primaryColor;
                    border: 1px solid $primaryColor;
                    opacity: 0.6;
                    transition: 0.3s ease-in-out;
                }
            }
        }
    }
}
.errmsg {
    color: #f70000;
    margin-bottom: 0px;
}
.term-condtn_err {
    margin-left: 20px;
}
