.landingbanner_wrapper {
    background-color: #f9f8f4;
}

.landingbanner_section {
    padding: 35px;
    padding-top: 120px;
    // height: calc(100vh - 89px);
    max-width: 1640px;
    margin: 0px auto;
    .bannersection_content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        // align-items: center;
        .title-image {
            h1 {
                width: 100%;
                font-family: $hellix;
                font-weight: 700;
                font-size: 32px;
                line-height: 112.5%;
                text-align: center;
                letter-spacing: -0.01em;
                text-transform: capitalize;
                margin-bottom: 8px;
                text-align: center;
                position: relative;
            }
            .frame_img {
                width: 100%;
                text-align: center;
                display: flex;
                flex-direction: column;
                align-items: center;
                img {
                    width: 200px;
                }
            }
            .star-icon {
                position: absolute;
                top: 0px;
                right: -6px;
                @media only screen and (min-width: 768px) {
                    right: 170px;
                    top: -12px;
                }
                @media only screen and (min-width: 1024px) {
                    right: 20px;
                }
                @media only screen and (min-width: 1360px) {
                    right: 20px;
                    top: 0px;
                }
            }
        }
        p {
            width: 100%;
            // max-width: 380px;
            text-align: center;
            font-family: $hellix;
            font-weight: 400;
            font-size: 16px;
            line-height: 140%;
            color: $gray-2;
            margin-bottom: 32px;
            // margin: 24px auto;
        }
        .btn-price {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 24px;
            a {
                font-weight: 600;
                font-size: 16px;
                line-height: 19px;
                // text-decoration-line: underline;
                // border-bottom: 1px solid #000000;
                color: $tertiaryColor;
            }
        }
        .databox {
            display: flex;
            justify-content: center;
            margin: 35px 0;
            gap: 16px;
            div {
                div {
                    &:nth-child(1) {
                        font-family: $hellix;
                        font-weight: 700;
                        font-size: 24px;
                        line-height: 105.52%;
                        letter-spacing: -0.01em;
                        text-transform: uppercase;
                        color: $tertiaryColor;
                        text-align: center;
                        margin-bottom: 8px;
                    }
                    &:nth-child(2) {
                        font-family: $hellix;
                        font-weight: 400;
                        font-size: 13px;
                        line-height: 140%;
                        text-align: center;
                        color: $gray-2;
                    }
                }
            }
        }
    }
    .bannersection_image {
        width: 100%;
        img {
            width: 100%;
        }
    }
}

@media only screen and (min-width: 860px) {
    .landingbanner_section {
        display: flex;
        padding-top: 0px;
        align-items: center;
        padding: 20px 120px 40px 40px;
        .bannersection_content {
            width: 50%;
            justify-content: flex-start;
            .title-image {
                width: 100%;
                display: flex;
                h1 {
                    text-align: start;
                    font-family: "Hellix";
                    font-style: normal;
                    font-weight: 700;
                    line-height: 65px;
                    letter-spacing: -0.01em;
                    text-transform: capitalize;
                    margin-bottom: 0px;
                }
                .frame_img {
                    display: flex;
                    flex-direction: row;
                    text-align: start;
                }
            }
            p {
                text-align: start;
                margin-top: 18px;
                margin-bottom: 40px;
            }
            .btn-price {
                justify-content: flex-start;
                .see-price {
                    display: flex;
                    font-family: $hellix;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 28px;
                    color: #171717;
                    position: relative;
                    &::before {
                        display: block;
                        content: "";
                        border-bottom: 2px solid #4a4a4a;
                        width: 100%;
                        position: absolute;
                        bottom: -2px;
                        left: 0;
                        transition: all 300ms ease-out;
                    }
                    &:hover::before {
                        width: 0%;
                        left: 50%;
                        color: $primaryColor;
                    }
                }
            }
            .databox {
                width: 100%;
                justify-content: flex-start;
                margin-top: 60px;
                div {
                    width: 140px;
                    div {
                        &:nth-child(1) {
                            text-align: start;
                        }
                        &:nth-child(2) {
                            text-align: start;
                        }
                    }
                }
            }
        }
        .bannersection_image {
            width: 50%;
        }
    }
}

@media only screen and (min-width: 1024px) {
    .landingbanner_section {
        padding: 90px 80px 60px 80px;
        .bannersection_content {
            .btn-price {
                justify-content: flex-start;
            }
            .databox {
                margin-bottom: 0px;
            }
        }
        .bannersection_image {
            max-height: 500px;
        }
    }
}

@media only screen and (min-width: 1280px) {
    .landingbanner_section {
        padding: 90px 100px 80px 100px;
        .bannersection_content {
            width: 52%;
            .title-image {
                h1 {
                    font-size: 58px;
                }
            }
        }
    }
}

@media only screen and (min-width: 1360px) {
    .landingbanner_section {
        padding: 90px 100px 100px 100px;
        .bannersection_content {
            width: 50%;
            padding-top: 20px;
        }
    }
}
@media only screen and (min-width: 1600px) {
    .landingbanner_section {
        padding: 90px 100px 140px 100px;
    }
}
