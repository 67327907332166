.landingMenu_wrapper {
    background-color: #f9f8f4;
    padding: 60px 16px;
    h2 {
        text-align: center;
    }
    .landingmenu_container {
        .landingmenu_left {
            margin-bottom: 32px;
            // height: 380px;

            img {
                width: 100%;
                height: 100%;
                // border-radius: 20px;
                // border: 2px solid #171717;
                // border-right: 6px solid #171717;
                // border-bottom: 6px solid #171717;
                // object-fit: cover;
            }
        }
        .landingmenu_right {
            // margin-top: -22px;
            .ant-collapse {
                border-bottom: 0.5px solid rgba(0, 0, 0, 0.3) !important;

                .ant-collapse-item:nth-child(n) {
                    border-bottom: none;
                }
                .ant-collapse-header {
                    border: none;
                    display: flex;
                    flex-direction: row-reverse;
                    justify-content: start;
                    align-items: center;
                    gap: 16px;
                    padding: 0px;
                    margin: 16px 0px 16px 0px;
                    font-size: 20px;
                    font-weight: 700;
                    line-height: 42px;
                    @media only screen and (min-width: 1024px) {
                        font-size: 32px;
                    }
                }
                &:last-child {
                    border-bottom: none !important;
                }
                &:first-child {
                    margin-top: 0px !important;
                }
            }
            .landingmenu_box {
                &:last-child {
                    border-bottom: transparent;
                    padding-bottom: 0px;
                }
                margin: 24px 0px;
                padding-bottom: 24px;
                border-bottom: 0.5px solid rgba(0, 0, 0, 0.3);
                div {
                    display: flex;
                    align-items: center;
                    gap: 12px;
                    &:nth-child(2) {
                        font-family: $hellix;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 26px;
                        color: #4a4a4a;
                    }
                    h3 {
                        margin: 0px;
                        font-family: $hellix;
                        font-weight: 700;
                        font-size: 20px;
                        line-height: 25px;
                        letter-spacing: -0.01em;
                        color: #171717;
                    }
                }
            }
            .ant-collapse {
                border: none;
                background-color: #f9f8f4;
                .ant-collapse-item:nth-child(1) {
                    margin-top: 0px !important;
                }
                // padding: 16px;
                .anticon svg {
                    display: none;
                }
                .ant-collapse-extra {
                    margin: 0px;
                }
                .menu_iconwrapper {
                    background-color: #111111;
                    width: 32px;
                    height: 32px;
                    border-radius: 8px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                .ant-collapse-content {
                    border: none;
                }
                .ant-collapse-item {
                    border-radius: 0px;
                    // border-bottom: 0.5px solid rgba(0, 0, 0, 0.3);
                    // border: none;
                }
                .ant-collapse-content-box {
                    background-color: #f9f8f4;
                    border-top: none;
                    padding: 0px 0px 25px 0px;
                    p {
                        margin: 0px;
                        font-family: $hellix;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 26px;
                        color: #4a4a4a;
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 768px) {
    .landingMenu_wrapper {
        margin: 0px 40px;
        padding: 40px;
        position: relative;
        border-radius: 45px;
        .imageiconI {
            position: absolute;
            top: -30px;
            left: -26px;
        }
        .imageiconII {
            position: absolute;
            bottom: -60px;
            right: -26px;
        }
        .landingmenu_container {
            display: flex;
            align-items: center;
            gap: 40px;
            .landingmenu_left {
                width: 50%;
                // margin-top: 24px;
                margin-bottom: 0px;
            }
            .landingmenu_right {
                width: 50%;
            }
        }
    }
}

@media only screen and (min-width: 1024px) {
    .landingMenu_wrapper {
        margin: 0px 80px;
        padding: 80px;
        h2 {
            margin-bottom: 60px;
        }
        .landingmenu_container {
            display: flex;
            gap: 120px;
            .landingmenu_left {
                width: 50%;
                // margin-top: 24px;
                margin-bottom: 0px;
            }
            .landingmenu_right {
                width: 50%;
            }
        }
    }
}

@media only screen and (min-width: 1280px) {
    .landingMenu_wrapper {
        margin: 0px 100px;
        padding: 100px 130px;
    }
}

@media only screen and (min-width: 1480px) {
    .landingMenu_wrapper {
        margin: 0px 0px;
        padding: 100px 130px;
    }
}
