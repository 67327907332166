.receipt_wrapper {
    // padding: 40px 0;
    padding-bottom: 20px;
    .receipt_container {
        // height: calc(100vh - 50px);
        .receipt-logo {
            display: flex;
            align-items: center;
            flex-direction: column;
            width: 100%;
            height: 150px;
            object-fit: cover;
            margin: auto;
            img {
                width: 100%;
                height: 100%;
            }
            div {
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
                color: $primaryColor;
                margin-top: 16px;
            }
        }
        .receipt-logoafterdownload {
            display: none;
        }
        .order-receipt {
            background-color: #fafafa;
            padding: 60px 30px 40px 30px;
            margin-bottom: 20px;
            @media only screen and (min-width: 560px) {
                padding: 40px 40px 24px 40px;
            }
            h2 {
                font-weight: 600;
                font-size: 20px;
                line-height: 140%;
                text-align: center;
                color: #212121;
            }
            .receipt_productcontainer {
                border-top: 1px solid #b3b3b3;
                border-bottom: 1px solid #b3b3b3;
                // margin-bottom: 16px;
                .receipt-product {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    padding: 16px 0;
                    border-bottom: 1px solid #f2f2f2;
                    .receipt_toppingsection {
                        display: flex;
                        flex-direction: column;
                        div {
                            display: flex;
                        }
                    }
                    &:last-child {
                        border-bottom: 0px;
                    }
                }
                div {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: 0px;
                    .receipt_productquantity {
                        font-weight: 400;
                        font-size: 13px;
                        line-height: 18px;
                        color: #595959;
                        margin: 0px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 30px;
                    }
                    h4 {
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 22px;
                        color: #595959;
                        max-width: 160px;
                        margin: 0px;
                        word-break: break-all;
                        text-transform: capitalize;
                        display: flex;
                        sub {
                            display: flex;
                            align-items: center;
                        }
                        @media only screen and (min-width: 560px) {
                            width: 300px;
                        }
                    }
                    h5 {
                        font-weight: 400;
                        font-size: 13px;
                        line-height: 18px;
                        color: #595959;
                        max-width: 160px;
                        margin: 0px;
                        word-break: break-all;
                        @media only screen and (min-width: 560px) {
                            width: 300px;
                        }
                    }
                    div {
                        .receipt_productprice {
                            font-weight: 500;
                            font-size: 16px;
                            line-height: 22px;
                            margin: 0px;
                            display: flex;
                            justify-content: end;
                            width: auto;
                        }
                        .receipt_productqty {
                            font-weight: 400;
                            font-size: 13px;
                            line-height: 22px;
                            text-align: right;
                            color: #212121;
                            min-width: 76px;
                            display: flex;
                            justify-content: end;
                            margin: 0px;
                            width: 30px;
                        }
                    }
                    &:nth-child(2) {
                        margin: 0px;
                    }
                }
            }
            .pricing {
                margin: 0px;
                padding: 16px 0;
                box-shadow: none;
                .price {
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 21px;
                    color: #212121;
                }
                .total {
                    font-weight: 500;
                    font-size: 20px;
                    line-height: 28px;
                    color: #212121;
                }
            }
        }
    }
    .download_receipt {
        display: flex;
        justify-content: center;
        gap: 12px;
        img {
            cursor: pointer;
        }
        span {
            font-weight: 600;
            font-size: 16px;
            line-height: 21px;
            color: #6690e4;
            cursor: pointer;
        }
    }
}
@media print {
    @page {
        margin-top: 0;
        margin-bottom: 0;
    }
    .receipt_wrapper {
        padding-top: 80px;
    }
    .receipt-logo {
        display: none !important;
    }
    .receipt-logoafterdownload {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 40px auto;
        img {
            width: 83px;
            height: 80px;
            border-radius: 50%;
            margin-bottom: 16px;
        }
    }
    .pricing {
        border: none !important;
        box-shadow: none;
    }
    .order-receipt {
        background-color: #881717;
        padding: 14px;
        margin-top: 16px;
        h2 {
            text-align: center;
        }
    }
    .receipt_productcontainer {
        border-top: 1px solid #b3b3b3;
        border-bottom: 1px solid #b3b3b3;
        padding: 16px 0 0 0;
        margin-bottom: 16px;
    }
    .receipt-logo {
        display: flex;
        align-items: center;
        flex-direction: column;
    }
    .receipt-product {
        margin-bottom: 8px;
    }
    .receipt-product div {
        display: flex;
        justify-content: space-between;
    }
    .receipt_toppingsection {
        display: flex;
        flex-direction: column;
    }
}
