.terms_privacy {
    max-width: 794px;
    margin: auto;
    margin-top: 120px;
    margin-bottom: 80px;
    h2 {
        font-weight: 600;
        font-size: 24px;
        line-height: 34px;
        color: #091731;
        @media only screen and (min-width: 768px) {
            font-size: 36px;
            line-height: 54px;
        }
    }
    p {
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        color: #454545;
        a {
            text-decoration: underline;
            color: #e42323;
        }
    }
}

.terms_wrapper {
    ol {
        li {
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color: #091731;
            margin-bottom: 30px;
            @media only screen and (min-width: 768px) {
                font-size: 25px;
                line-height: 35px;
            }
            p {
                &:nth-child(1) {
                    margin-top: 8px;
                }
            }
            ol {
                // margin-left: -34px;
                margin-top: 8px;
                li {
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 22px;
                    color: #454545;
                    margin-bottom: 16px;
                    margin-left: -20px;
                }
            }
        }
    }
    ol {
        list-style-type: none;
        counter-reset: item;
        margin: 0;
        padding: 0;
    }
    li {
        display: table;
        counter-increment: item;
        margin-bottom: 0.6em;
    }
    li:before {
        content: counters(item, ".") ". ";
        display: table-cell;
        padding-right: 0.6em;
    }
    li li {
        margin: 0;
    }
    li li:before {
        content: counters(item, ".") " ";
    }
}

.privacy_wrapper {
    ol {
        padding: 16px;
        li {
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color: #091731;
            margin-bottom: 8px;
            margin-left: 20px;
            @media only screen and (min-width: 768px) {
                font-size: 25px;
                line-height: 35px;
                margin-left: 30px;
            }
        }
        div {
            margin-bottom: 30px;
            ul {
                li {
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 22px;
                    color: #454545;
                }
            }
        }
    }
    .address_list {
        list-style: none;
        margin: 0px;
    }
}