.successfully_ordercontainer {
    .ordr_endcover {
        img {
            position: relative;
            width: 100%;
            height: 150px;
            object-fit: cover;
        }
    }
    .endcover_check {
        background-color: $primaryColor;
        width: 55px;
        height: 55px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $secondaryColor;
        font-size: 24px;
        position: absolute;
        left: calc(50% - 27.5px);
        top: 120px;
        border: 2px solid $secondaryColor;
    }
    .order_successfulcontent {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: calc(100vh - 184px);
        // padding: 0 30px;
        .end_content {
            margin-top: 34px;
            padding: 0px 30px;
            @media only screen and (min-width: 425px) {
                padding: 0px 40px;
            }
            h2 {
                font-weight: 600;
                font-size: 20px;
                line-height: 28px;
                text-align: center;
                color: $primaryColor;
            }
            p {
                font-weight: 400;
                font-size: 16px;
                line-height: 22px;
                text-align: center;
                color: #8b8b8b;
                margin-bottom: 32px;
            }
        }
        .end_viewreceipt {
            background-color: #fafafa;
            // margin: 0px 30px;
            padding: 20px 30px;
            margin-bottom: 32px;
            @media only screen and (min-width: 425px) {
                padding: 20px 40px;
            }
            .view_receiptlist {
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-weight: 400;
                font-size: 16px;
                line-height: 22px;
                color: $primaryColor;
                padding: 14px 0;
                &:nth-child(2) {
                    border-top: 1px solid #b3b3b3;
                    // border-top: none;
                }
                div {
                    display: flex;
                    gap: 14px;
                    .view_receipt {
                        margin-left: 4px !important;
                    }
                    span {
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 140%;
                        /* or 22px */
                        text-align: right;
                        color: $primaryColor;
                    }
                }
            }
            .view_receiptwrap {
                border-top: 1px solid #b3b3b3;
                border-bottom: 1px solid #b3b3b3;
            }
        }
        .end_btn {
            padding: 0 30px 30px 30px;
            @media only screen and (min-width: 768px) {
                padding: 0 30px 0 30px;
            }
            .btn-black-outlined {
                width: 100%;
            }
        }
    }
}

.receiptlist_heading {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 0px;
}

// .greeting_container {
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     background-color: $primaryColor;
//     min-height: 100vh;
//     padding: 16px;
//     .ending__logo {
//         width: 167px;
//         img {
//             width: 100%;
//         }
//     }
//     .thanks_content {
//         display: flex;
//         flex-direction: column;
//         justify-content: center;
//         align-items: center;
//         div:nth-child(2) {
//             font-family: "Inter";
//             font-style: normal;
//             font-weight: 800;
//             font-size: 36.4162px;
//             line-height: 44px;
//             color: $secondaryColor;
//             margin: 27px 0px 22px 0px;
//         }
//     }
//     .thankyou_check {
//         background-color: $secondaryColor;
//         border-radius: 50%;
//         width: 116px;
//         height: 116px;
//         display: flex;
//         justify-content: center;
//         align-items: center;
//         .thanks_checkicon {
//             font-size: 40px;
//         }
//     }
//     .lastbutton_content {
//         display: flex;
//         flex-direction: column;
//         width: 100%;
//         gap: 16px;
//     }
//     p {
//         color: $secondaryColor;
//         font-weight: 500;
//         font-size: 13px;
//         line-height: 16px;
//         text-align: center;
//         color: $secondaryColor;
//     }
// }
