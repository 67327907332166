.landing_nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    background-color: #f9f8f4;
    padding: 18px 16px;
    position: fixed;
    top: 0px;
    width: 100%;
    margin: auto;
    z-index: 9999;
    height: 86px;
    .landingnav_innerwrapper {
        max-width: 1440px;
        width: 100% !important;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0px auto;
        .landing_logo {
            width: 50px;

            @media only screen and (min-width: 1024px) {
                width: auto;
            }
            img {
                width: 100%;
                height: 100%;
                cursor: pointer;
            }
        }
        .landing_nav-bar {
            font-size: 40px;
        }
        .landingnav-right {
            display: flex;
            align-items: center;
            gap: 16px;
            font-family: $hellix;
            font-weight: 400;
            .landingright_btns {
                display: flex;
                gap: 12px;
                align-items: center;
                display: none;
                .btn-smbutton {
                    padding: 14px 24px;
                    min-width: 126px;
                }
            }
            .burgermenucontainer {
                display: flex;
                flex-direction: column;
                span {
                    width: 32px;
                    height: 4px;
                    background-color: $primaryColor;
                    border-radius: 2px;
                    margin: 4px;
                    transition: all ease 0.5s;
                }
                .open {
                    &:nth-child(1) {
                        transform: rotate(45deg) translateY(17px);
                    }
                    &:nth-child(2) {
                        opacity: 0;
                    }
                    &:nth-child(3) {
                        transform: rotate(-45deg) translateY(-17px);
                    }
                }
                .close {
                    &:nth-child(1) {
                        transform: rotate(0) translateY(0);
                    }
                    &:nth-child(2) {
                        opacity: 1;
                    }
                    &:nth-child(3) {
                        transform: rotate(0) translateY(0);
                    }
                }
            }
            .login_link {
                color: $primaryColor;
            }
            .nav-link {
                display: flex;
                align-items: center;
                ul {
                    display: flex;
                    align-items: center;
                    margin: 0px;
                    gap: 46px;
                    margin-right: 24px;
                    .landing_menulink {
                        text-decoration: none;
                        color: #4a4a4a;
                        transition: all 0.05s ease-out;
                        font-family: $hellix;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 19px;
                        &:hover {
                            color: $primaryColor;
                        }
                    }
                    .landing_menulink:after {
                        display: block;
                        content: "";
                        border-bottom: solid 2px $gray-2;
                        transform: scaleX(0);
                        transition: transform 600ms ease-in-out;
                    }
                    // .landing_menulink:hover:after {
                    //     transform: scaleX(1);
                    //     border-bottom: 2px solid $gray-2;
                    //     color: $primaryColor;
                    // }
                    .landing_menulink-active {
                        text-decoration: none;
                        color: #4a4a4a;
                        transition: all 0.05s ease-out;
                        font-family: $hellix;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 19px;
                        &:hover {
                            color: $primaryColor;
                        }
                    }
                    .landing_menulink-active:after {
                        display: block;
                        content: "";
                        border-bottom: solid 2px $gray-2;
                        transform: scaleX(0);
                        transition: transform 600ms ease-in-out;
                    }
                    .landing_menulink-active:hover:after {
                        transform: scaleX(1);
                        border-bottom: 2px solid $gray-2;
                        color: $primaryColor;
                    }
                    ////
                    ///
                    // .landing_menulink {
                    //     color: #4a4a4a;
                    //     font-weight: 400;
                    //     font-size: 16px;
                    //     line-height: 19px;
                    //     transition: all 1s;
                    //     position: relative;
                    // }
                    // .landing_menulink:hover::before {
                    //     content: "";
                    //     position: absolute;
                    //     bottom: -4px;
                    //     left: 0;
                    //     height: 0;
                    //     width: 0;
                    //     z-index: 1;
                    //     color: #000;
                    //     border-bottom: 3px solid #4a4a4a;
                    //     animation: grow 0.6s linear;
                    //     animation-fill-mode: forwards;
                    // }
                    // .landing_menulink:hover {
                    //     color: #000;
                    // }
                    // @keyframes grow {
                    //     100% {
                    //         width: 100%;
                    //     }
                    // }
                    // .menu-active {
                    //     color: #000;
                    //     border-bottom: 3px solid #4a4a4a;
                    // }
                }
                li {
                    list-style: none;
                    display: flex;
                    justify-content: center;
                }
            }
        }
    }
}

.landing_nav.active {
    box-shadow: 0px 2px 11px rgba(0, 0, 0, 0.1);
    animation: slide-animation 1s ease-out;
}

@keyframes slide-animation {
    from {
        transform: translateY(-100%);
    }
    to {
        transform: translateY(0);
    }
}

.landing-nav-drawer {
    .ant-drawer-mask {
        background-color: transparent;
    }
    .nav-link {
        height: calc(100vh - 110px);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        ul {
            li {
                list-style: none;
                padding: 23px;
                display: flex;
                justify-content: center;
                font-family: "Hellix" !important;
                font-weight: 400;
                font-size: 17px;
                line-height: 24px;
                border-bottom: 1px solid rgba(68, 68, 68, 0.09);
                &:last-child {
                    border-bottom: none;
                }
                a {
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 24px;
                    color: $gray-2;
                }
            }
        }
    }
    .ant-drawer-content-wrapper {
        background-color: #f9f8f4 !important;
        width: 100% !important;
        height: 100% !important;
        transition: all ease 0.8s;
        position: absolute;
        top: 87px;
        right: 0px;
        box-shadow: 0px 2px 11px $gray-2;
        .ant-drawer-title {
            display: flex;
        }
        .ant-drawer-content {
            background-color: transparent;
        }
    }
    .ant-drawer-header-title {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
    }
    .ant-drawer-body {
        padding: 0px 24px;
    }
    .ant-drawer-close {
        margin: 0px;
        color: $primaryColor;
    }
    .ant-drawer-header {
        display: none;
        justify-content: space-between;
        background-color: transparent;
    }
    .landing_logo {
        width: 100px !important;
    }
    .landingdrawer_logo {
        width: 70px;
        img {
            width: 100% !important;
        }
    }
}

@media only screen and (min-width: 425px) {
    .landing-nav-drawer {
        .ant-drawer-content-wrapper {
            width: 60% !important;
        }
    }
}

@media only screen and (min-width: 768px) {
    .landing-nav-drawer {
        .ant-drawer-content-wrapper {
            width: 32% !important;
        }
    }
}

@media only screen and (min-width: 860px) {
    .landingnav-right {
        display: flex;
        flex-direction: row-reverse;
        gap: 40px;
        .landingright_btns {
            display: flex !important;
            gap: 12px;
            align-items: center;
        }
    }
    .landing_nav {
        padding: 20px 40px;
        .landing_logo {
            width: 100px;
            height: 30px;
            img {
                width: 100%;
            }
        }
    }
}

@media only screen and (min-width: 1024px) {
    .landingnav-right {
        justify-content: space-between;
        width: 75%;
    }
    .landing_nav {
        padding: 40px 80px;
    }
}

@media only screen and (min-width: 1280px) {
    .landingnav-right {
        width: 73%;
    }
    .landing_nav {
        padding: 40px 100px;
    }
}
