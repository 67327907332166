.empty-cart {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 16px;
    height: calc(100vh - 196px);
    @media only screen and (min-width: 768px) {
        height: 82vh;
    }
    .emptycart__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        img {
            width: 64px;
            height: 54px;
            object-fit: cover;
        }
        p {
            font-weight: 500;
            font-size: 13px;
            line-height: 16px;
            text-align: center;
            color: #646268;
            margin-top: 13px;
        }
    }
    .back-btn {
        position: absolute;
        bottom: 0px;
        margin-left: -16px;
        width: 100%;
        padding: 16px;

        // button {
        //     width: 100%;
        //     padding: 16px;
        //     width: 100%;
        //     background: #000000;
        //     border-radius: 8px;
        //     font-weight: 600;
        //     font-size: 22px;
        //     line-height: 27px;
        //     text-align: center;
        //     color: #ffffff;
        //     border: none;
        // }
    }
}
