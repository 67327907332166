.trusted_section {
    padding: 60px 16px;
    div {
        &:nth-child(1) {
            font-family: $hellix;
            font-weight: 600;
            font-size: 18px;
            line-height: 24px;
            text-align: center;
            letter-spacing: 0.35em;
            text-transform: uppercase;
            color: rgba(74, 74, 74, 0.8);
            // margin-bottom: 16px;
        }
        &:nth-child(2) {
            font-family: $hellix;
            font-weight: 700;
            font-size: 42px;
            line-height: 52px;
            text-align: center;
            letter-spacing: -0.01em;
            text-transform: capitalize;
            color: #171717;
        }
    }
    .trusted_imgsection {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        margin-top: 32px;

        .trusted_imagewrapper {
            width: 33%;
            border-right: 0.337945px dashed #c4c4c4;
            border-bottom: 0.337945px dashed #c4c4c4;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 30px;
            &:nth-child(3) {
                border-right: none;
            }
            &:nth-child(4) {
                border-bottom: none;
                justify-content: flex-end;
                width: 50%;
                padding-right: 36px;
                @media only screen and (min-width: 860px) {
                    padding-top: 30px;
                }
            }
            &:nth-child(5) {
                border: none;
                width: 50%;
                padding-left: 36px;
                justify-content: flex-start;
                @media only screen and (min-width: 860px) {
                    padding-top: 30px;
                }
            }
            img {
                background-color: #ffffff;
                width: 40%;
                height: 70%;
                // margin-bottom: 30px;
                // object-fit: cover;
            }
        }
    }
    .video-section {
        width: 100%;
        position: relative;
        margin-top: 40px;
        // margin-bottom: 150px;
        @media only screen and (min-width: 1024px) {
            margin-bottom: 0px;
        }

        div {
            height: 250px !important;
            .react-player__preview {
                border-radius: 20px;
                height: 100%;
                object-fit: cover;
                min-height: 250px;

                @media only screen and (min-width: 1024px) {
                    border-radius: 40px;
                }
            }
            // img {
            //     width: 100%;
            //     height: 250px;
            //     border-radius: 20px;
            // }
            .playicon_border {
                position: absolute;
                right: calc(50% - 50px);
                // top: calc(50% - 50px);
                background-color: rgba(23, 23, 23, 0.4);
                border-radius: 50%;
                width: 100px;
                height: 100px !important;
                display: flex;
                justify-content: center;
                align-items: center;
                div {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: rgba(23, 23, 23, 0.4);
                    width: 78px;
                    height: 78px !important;
                    border-radius: 50%;
                    button {
                        width: 60px;
                        height: 60px;
                        border-radius: 50%;
                        background-color: #000000;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        .playcircle-icon {
                            font-size: 40px;
                            // background-color: #000000;
                            border-radius: 50%;
                            color: $secondaryColor;
                        }
                    }
                }
            }
            .react-player__preview {
                margin: 0px;
            }
        }
    }
}

.ytp-large-play-button {
    background-color: rgba(23, 23, 23, 0.4);
}

@media only screen and (min-width: 560px) {
    .trusted_imgsection {
        .trusted_imagewrapper {
            height: 60px !important;
        }
    }
}

@media only screen and (min-width: 860px) {
    .trusted_section {
        padding: 40px;
    }
}

@media only screen and (min-width: 1024px) {
    .trusted_section {
        padding: 80px 80px 140px 80px;
        .trusted_imgsection {
            margin-top: 45px;
            .trusted_imagewrapper {
                height: 90px !important;
            }
        }
        .video-section {
            margin-top: 100px;
            div {
                height: 540px !important;
                .react-player__preview {
                    border-radius: 40px;
                    height: 100%;
                    // height: 720px !important;
                }
            }
        }
    }
}

@media only screen and (min-width: 1280px) {
    .trusted_section {
        padding: 80px 100px 140px 100px;
    }
}
