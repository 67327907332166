.verify_wrapper {
    background: #f3f3f3;
    border: 1px solid #000000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
}
.v_innercomp {
    background: #ffffff;
    padding: 24px;
}
.v_innercomp .form_logo {
    margin-bottom: 16px;
}
.v_bottomcontent {
    width: 200px;
    display: flex;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 24px;
}
.v_bottomcontent div {
    text-decoration: underline;
}
