$primaryColor: #212121;
$secondaryColor: #ffffff;
$tertiaryColor: #171717;
$gray-1: #646268;
$gray-2: #4a4a4a;
$gray-3: #8b8b8b;
$b-color: #f9f8f4;
$hellix: "Hellix";
$blue: #004ef9;
$d-gray: #595959;
$font-fam: "Manrope", HelveticaNeue, sans-serif;
$mediumfont-fam: HelveticaNeue-medium;
$fontfam-1: "TT Norms Pro";
