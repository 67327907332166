.landing-footer {
    display: flex;
    flex-direction: column;
    padding: 60px 16px;
    padding-bottom: 32px;
    position: relative;
    .bread_footer {
        display: none;
        @media only screen and (min-width: 1024px) {
            display: block;
            position: absolute;
            top: 80px;
            right: 0px;
        }
    }

    .landing-topfooter {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-bottom: 60px;
        border-bottom: 1px solid #d9dbe9;
        margin-bottom: 60px;

        img {
            width: 136px;
        }
        p {
            font-family: $hellix;
            margin-top: 14px;
            font-weight: 400;
            font-size: 16px;
            line-height: 30px;
            text-align: center;
            color: $gray-2;
            margin-bottom: 0px;
            max-width: 300px;
        }
    }
    .landing-footerlistwrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        row-gap: 40px;
        // flex-direction: column;
        .landing-bottomfooter {
            width: 50%;
            .footer-list {
                ul {
                    li {
                        font-family: $hellix;
                        list-style: none;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 20px;
                        color: $gray-2;
                        cursor: pointer;
                    }
                }
            }
        }
        .subscribe_section {
            p {
                font-weight: 400;
                font-size: 16px;
                line-height: 30px;
                margin-bottom: 16px;
                color: $gray-2;
                margin-bottom: 24px;
            }
            .subscribe_field {
                position: relative;
                input {
                    border: 3px solid #171717;
                    border-right: 6px solid #171717;
                    border-bottom: 6px solid #171717;
                    border-radius: 110px;
                    width: 100%;
                    height: 100%;
                    padding: 24px;
                    outline: none;
                    padding-right: 120px;
                    padding-left: 8px;
                    font-family: $hellix;
                    font-size: 16px;
                    font-weight: 500;
                    // &::placeholder {
                    //     font-size: 12px;
                    // }
                }
                button {
                    position: absolute;
                    right: 10px;
                    top: 10px;
                }
            }
        }
    }
    .copyright-section {
        display: flex;
        flex-wrap: wrap;
        gap: 32px;
        border-top: 1px solid #d9dbe9;
        margin-top: 60px;
        padding-top: 32px;
        align-items: center;

        p,
        .privacy_ctn {
            margin: 0px;
            font-family: $hellix;
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            color: #4a4a4a;
            cursor: pointer;
        }
        .landing_socialicons {
            display: flex;
            gap: 20px;
        }
    }
    .footer-title {
        font-family: $hellix;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        color: #050505;
        margin-bottom: 24px;
    }
}

@media only screen and (min-width: 768px) {
    .landing-topfooter {
        p {
            max-width: 400px !important;
        }
    }
    .landing-footer {
        .landing-footerlistwrapper {
            .landing-bottomfooter {
                width: 33.33%;
            }
            .subscribe_section {
                width: 66%;
            }
        }

        .copyright-section {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-top: 32px;
        }
    }
}

@media only screen and (min-width: 1024px) {
    .landing-footer {
        padding: 60px 80px;
        .landing-topfooter {
            flex-direction: row;
            justify-content: space-between;
            p {
                width: 50%;
                max-width: 526px;
                margin: 0px;
                font-size: 18px;
                line-height: 30px;
            }
        }
        .landing-footerlistwrapper {
            .landing-bottomfooter {
                width: 25%;
                .footer-title {
                    margin-bottom: 40px;
                }
                .footer-list {
                    ul {
                        li {
                            font-size: 18px;
                            line-height: 24px;
                        }
                    }
                }
            }
            .subscribe_section {
                width: 100%;
                .footer-title {
                    margin-bottom: 40px;
                }
            }
        }
    }
    .copyright-section {
        padding-top: 60px !important;
        p {
            font-weight: 400;
            font-size: 18px !important;
            line-height: 20px;
            color: #4a4a4a;
            margin: 24px 0;
        }
    }
}

@media only screen and (min-width: 1360px) {
    .landing-footer {
        padding: 60px 100px;
        .landing-topfooter {
            justify-content: space-between;
            p {
                width: 40%;
                text-align: start;
            }
        }
        .landing-footerlistwrapper {
            display: flex;
            flex-direction: row;
            .landing-bottomfooter {
                width: 18% !important;
            }
            .subscribe_section {
                width: 28%;
            }
        }
    }
}
