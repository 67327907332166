/* Ripple effect */

.ripple {
    background-position: center;
    transition: background 0.8s;
}

.ripple:hover {
    background: #000000 radial-gradient(circle, transparent 1%, #b4b0b03a 1%) center/15000%;
}

.ripple:active {
    background-color: #000000;
    background-size: 100%;
    transition: background 0s;
}


/* Button style */

.ripple {
    border: none;
    border-radius: 2px;
    padding: 12px 18px;
    font-size: 16px;
    text-transform: uppercase;
    cursor: pointer;
    color: white;
    background-color: #000000;
    outline: none;
}