.whole_listing {
    padding-bottom: 90px;
    .listing-menu {
        min-height: calc(100vh - 204px);
        .top-bar {
            display: flex;
            border-bottom: 1px solid #f1f1f1;
            padding: 9px 14px;
            justify-content: space-between;
            position: fixed;
            width: 100%;
            top: 56px;
            background-color: $secondaryColor;
            z-index: 1;
            .topbar_left {
                display: flex;
            }
            .topbar_right {
                align-items: center;
                background-color: #1d1d1d;
                border-radius: 185px;
                color: #fff;
                cursor: pointer;
                display: flex;
                justify-content: center;
                padding: 4px 12px;
                font-size: 16px;
                line-height: 22px;

                text-transform: capitalize;
                .viewmenu_arrow {
                    color: $secondaryColor;
                    font-size: 12px;
                    display: flex;
                    align-items: flex-end;
                    margin-left: 3px;
                    margin-top: 2px;
                }
            }
            .filter-icon,
            .search-icon {
                width: 32px;
                height: 32px;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                border: 1px solid #f1f1f1;
                margin-right: 8px;
                cursor: pointer;
                img {
                    width: 16px;
                    height: 16px;
                    object-fit: cover;
                }
                .bisearch {
                    color: #211e27;
                    font-size: 16px;
                }
            }
            .filter-icon,
            .search-icon:hover {
                background-color: #948f8f34;
                transition: all 0.6s ease-in;
            }
        }
        .search-bar {
            position: fixed;
            display: flex;
            justify-content: center;
            top: 56px;
            left: -100%;
            z-index: 10000;
            background-color: $secondaryColor;
            border-radius: 8px 8px;
            height: 50px;
            padding: 8px 14px 8px 14px;
            width: 100%;
            transition: all 0.5s ease-out;
            .input-search {
                width: calc(100% - 98px);
                position: relative;
                input {
                    border: 1px solid #f1f1f1;
                    border-radius: 185px;
                    padding: 5px 18px;
                    padding-left: 30px;
                    width: 100%;
                    outline: none;
                }
                .bisearch_inner-icon {
                    position: absolute;
                    left: 10px;
                    top: 11px;
                    color: #211e27;
                }
            }
            .cancel-btn {
                width: 86px;
                border: 1px solid #f1f1f1;
                border-radius: 185px;
                font-weight: 600;
                font-size: 14px;
                line-height: 17px;
                color: #211e27;
                padding: 8px 0;
                display: flex;
                justify-content: center;
            }
        }
        .search-bar.active {
            display: flex;
            align-items: center;
            justify-content: space-between;
            left: 0;
            top: 56px;
            height: 50px;
            z-index: 1000;
            width: 100%;
        }
        .menu_wrapper {
            padding: 64px 16px 16px 16px;
            h2 {
                text-transform: capitalize;
                font-weight: 700;
                font-size: 23px;
                line-height: 28px;
                color: $primaryColor;
                margin-bottom: 8px;
            }
            .menu_innerbox {
                display: flex;
                flex-direction: column;
                gap: 14px;
                .sold_out,
                .soldinactive {
                    left: 0px;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    background: linear-gradient(
                        to bottom,
                        rgba(33, 33, 33, 0.68) 0%,
                        rgba(33, 33, 33, 0.4) 100%
                    );
                    border-top-left-radius: 15px;
                    border-bottom-left-radius: 15px;
                    position: absolute;
                }
                .soldinactive {
                    border-radius: 8px;
                    @media only screen and (min-width: 768px) {
                        border-radius: 8px;
                    }
                }
                .sold_out {
                    width: 42.6%;
                }
                .sold_span,
                .sold_spancenter {
                    color: $secondaryColor;
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 16px;
                    position: absolute;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    top: 0px;
                    // left: calc(50% - 44px);
                    width: 100%;
                    height: 100%;
                }
                .sold_span {
                    position: absolute;
                    // left: 40px;
                }
                h2 {
                    width: 100%;
                    overflow-wrap: break-word;
                    font-weight: 700;
                    font-size: 23px;
                    line-height: 28px;
                    color: $primaryColor;
                    margin-bottom: 24px;
                    text-transform: capitalize;
                }
                .menuitems_wrap {
                    display: flex;
                    flex-wrap: wrap;
                    @media only screen and (min-width: 768px) {
                        gap: 16px;
                    }
                }
                // .menu_itemsactive {
                //     display: flex;
                //     border-radius: 0.25rem;
                //     border: 1px solid rgba(221, 221, 221, 0.4196078431);
                //     // height: 36.5vw;
                //     position: relative;
                //     background-color: #ffffff;
                //     transition: all 0.1s ease-in-out;
                //     box-shadow: 0 0 rgba(0, 0, 0, 0), 0 0 rgba(0, 0, 0, 0),
                //         0 0 rgba(0, 0, 0, 0), 0 0 rgba(0, 0, 0, 0),
                //         0px 0px 0px rgba(29, 29, 29, 0.25),
                //         0px 0px 15px rgba(29, 29, 29, 0.04);
                //     margin-bottom: 12px;
                //     @media only screen and (min-width: 768px) {
                //         margin-bottom: 0;
                //         height: 100%;
                //     }
                //     &:hover {
                //         transform: scale(1.01);
                //         @media only screen and (min-width: 768px) {
                //             transform: scale(1) !important;
                //         }
                //     }
                //     // &:last-child {
                //     //     margin: 0;
                //     // }
                //     @media only screen and (min-width: 768px) {
                //         max-height: 238px;
                //     }

                //     .listingproduct_img {
                //         aspect-ratio: 1/1;
                //         border-radius: 4px 0 0 4px;
                //         position: relative;
                //         width: 72%;

                //         @media only screen and (min-width: 768px) {
                //             // width: 192px;
                //         }
                //         img {
                //             width: 100%;
                //             height: 100%;
                //             border-top-left-radius: 0.25rem;
                //             border-bottom-left-radius: 0.25rem;
                //             object-fit: cover;
                //         }
                //     }
                //     .listingproduct_none {
                //         display: none;
                //     }
                // }
                //         .menu_inner_content,
                //         .menuinnercontent_opacity {
                //             padding: 12px;
                //         }

                //         .menu_inner_content,
                //         .menuinnercontent_opacity,
                //         .menu-wholecontent {
                //             display: flex;
                //             flex-direction: column;
                //             justify-content: space-between;
                //             width: -webkit-fill-available;
                //             max-width: 60%;
                //             // @media only screen and (min-width: 1024px) {
                //             //     max-width: 100%;
                //             // }
                //             // margin: 12px;
                //             // width: 55%;

                //             .menu_subcontentI {
                //                 // width: 100%;
                //                 div {
                //                     display: flex;
                //                     justify-content: space-between;
                //                     align-items: center;
                //                     margin-bottom: 4px;
                //                 }

                //                 h4 {
                //                     color: #1d1d1d;
                //                     font-size: 16px;
                //                     font-weight: 700;
                //                     line-height: 140%;
                //                     margin: 0;
                //                     overflow: hidden;
                //                     text-overflow: ellipsis;
                //                     text-transform: capitalize;
                //                     white-space: nowrap;
                //                 }
                //                 p {
                //                     color: #4d4d4d;
                //                     font-family: $font-fam;
                //                     font-size: 14px;
                //                     font-weight: 500;
                //                     line-height: 140%;
                //                     margin-bottom: 4px;
                //                     word-break: break-all;
                //                 }
                //                 .menulist_para {
                //                     display: -webkit-box;

                //                     -webkit-line-clamp: 2;
                //                     -webkit-box-orient: vertical;
                //                     overflow: hidden;
                //                     @media only screen and (min-width: 860px) {
                //                         -webkit-line-clamp: 2;
                //                     }
                //                 }
                //                 .menulist_popularp {
                //                     display: -webkit-box;

                //                     -webkit-line-clamp: 1;
                //                     -webkit-box-orient: vertical;
                //                     overflow: hidden;
                //                     @media only screen and (min-width: 860px) {
                //                         -webkit-line-clamp: 2;
                //                     }
                //                 }
                //                 .menulist_para.active {
                //                     display: none;
                //                     @media only screen and (min-width: 768px) {
                //                         display: block;
                //                     }
                //                 }
                //             }
                //             .aifire {
                //                 width: 24px;
                //                 height: 24px;
                //                 border-radius: 50%;
                //                 display: flex;
                //                 justify-content: center;
                //                 align-items: center;
                //                 background-color: $blue;
                //                 .aifire-icon {
                //                     font-size: 12px;
                //                     color: #ffffff !important;
                //                 }
                //             }
                //             .menu_subcontent {
                //                 display: flex;
                //                 // flex-direction: column;
                //                 justify-content: space-between;
                //                 align-items: flex-end;
                //                 .price {
                //                     font-size: 14px;
                //                     line-height: 18px;
                //                     font-weight: 400;
                //                     font-family: $font-fam;
                //                     color: $primaryColor;
                //                 }
                //                 .menu__GVD.active {
                //                     display: none;
                //                     @media only screen and (min-width: 768px) {
                //                         display: flex !important;
                //                         gap: 8px;
                //                     }
                //                 }
                //                 .guestreq_btn {
                //                     background-color: transparent;
                //                     border: none;
                //                     color: #000;
                //                     font-size: 16px;
                //                     font-style: normal;
                //                     font-weight: 500;
                //                     line-height: normal;
                //                     display: flex;
                //                     justify-content: center;
                //                     align-items: center;
                //                     cursor: pointer;
                //                     user-select: none;
                //                     transition: transform 0.3s ease;
                //                 }
                //             }
                //         }
                //         .menu-wholecontent {
                //             flex-direction: row;
                //             @media only screen and (min-width: 768px) {
                //                 flex-direction: column;
                //             }
                //         }
                //         .menuinnercontent_opacity {
                //             opacity: 0.6 !important;
                //         }
                //         .menu-wholecontent {
                //             padding: 16px 0;
                //             @media only screen and (min-width: 768px) {
                //                 padding: 0;
                //             }
                //         }
                //         .subcat_status {
                //             display: flex;
                //             justify-content: space-between;
                //             align-items: center;
                //             margin-bottom: 4px;
                //             .menu_status {
                //                 display: flex;
                //                 justify-content: center;
                //                 align-items: center;
                //                 width: 74px;
                //                 padding: 4px;
                //                 text-transform: uppercase;
                //                 border-radius: 32px;
                //                 color: $secondaryColor;
                //                 font-weight: 400;
                //                 font-size: 12px;
                //                 line-height: 15px;
                //                 background-color: $blue;
                //             }
                //         }

                //         .cat_name {
                //             font-weight: 600;
                //             font-size: 24px;
                //             line-height: 28px;
                //             color: $primaryColor;
                //             text-transform: capitalize;
                //             margin-bottom: 12px;
                //         }
                //         .catname_none {
                //             display: none !important;
                //         }
            }
        }
    }
    .view_orderbtn {
        width: 100%;
        padding: 20px;
        background-color: $secondaryColor;
        position: fixed;
        bottom: 0px;
        box-shadow: 0px 2px 11px rgba(0, 0, 0, 0.1);
        button {
            display: flex;
            justify-content: center;
            align-items: center;
            .viewordr_count_active {
                background-color: $secondaryColor;
                border-radius: 50%;
                width: 28px;
                height: 28px;
                padding: 12px;
                font-weight: 600;
                font-size: 15px;
                line-height: 18px;
                text-align: center;
                color: $blue;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-left: 20px;
                box-shadow: 6px 6px 11px rgba(0, 0, 0, 0.1);
            }

            @keyframes zoom-in {
                0%,
                20%,
                50%,
                80%,
                100% {
                    transform: translateY(0);
                }
                40% {
                    transform: translateY(-10px);
                }
                60% {
                    transform: translateY(-5px);
                }
            }
            .animated_count {
                transform: translate(-10, -10px);
                animation: zoom-in 0.5s ease-in-out;
            }

            #popup-button:focus + #popup-box {
                display: block;
            }
            .viewordr_count {
                display: none;
            }
        }
    }
    .footer {
        display: none;
    }
}

.listing_drawer {
    .ant-drawer-content-wrapper {
        width: 320px !important;
        margin-top: 107px;
        box-shadow: none !important;
        border: 0.5px solid #e3e3e3;
    }
    .ant-drawer-wrapper-body {
        position: relative;
        padding: 0px 16px;
    }
    .ant-drawer-header {
        border-bottom: 1px solid $secondaryColor !important;
        padding: 16px 8px;
    }
    .ant-drawer-mask {
        background-color: transparent !important;
    }
    .ant-drawer-body {
        padding: 0px;
        overflow: scroll;
    }
    .filter__drawer {
        -ms-overflow-style: none;
        scrollbar-width: none;
        &::-webkit-scrollbar {
            display: none;
        }
        .filter_content_wrapper {
            label {
                &:last-child {
                    border-bottom: none;
                }
            }
        }
        min-height: 91vh;
        height: auto;
        margin-bottom: 80px;
        overflow: scroll;
        // display: flex;
        // flex-direction: column;
        // justify-content: space-between;
        // height: 59vh;
        // overflow: scroll;
        // @media only screen and (min-width: 768px) {
        //     height: 67vh;
        // }
    }
    /* Hide scrollbar for Chrome, Safari and Opera */

    .filter_applybtn {
        background-color: $secondaryColor;
        padding: 20px 0;
        bottom: -1px;
        position: fixed;
        transition: all 0.9s ease;
        button {
            width: 100%;
            height: 46px;
            min-width: 288px;
            background-color: $blue;
            // padding: 16px 0px;
            color: $secondaryColor;
            border: none;
            border-radius: 5px;
            text-align: center;
            font-size: 18px;
            font-weight: 500;
            line-height: 140%;
        }
    }
    .ant-drawer-mask {
        opacity: 0 !important;
    }

    .drawer_dietry_content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 16px;
        border-bottom: 1px solid #f1f1f1;
        &:hover {
            background-color: #e9e9e9;
        }
        input {
            width: 20px !important;
            height: 20px !important;
            border-radius: 8px !important;
        }
        span {
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            text-align: center;
            color: $primaryColor;
        }
        .ant-drawer-title {
            font-weight: 700;
            font-size: 24px;
            line-height: 29px;
            text-align: center;
            color: $primaryColor;
        }
    }
    .filter_content_wrapper div:last-child {
        border-bottom: 1px solid $secondaryColor !important;
    }
    .ant-drawer-close {
        display: none;
    }
    .ant-drawer-body::-webkit-scrollbar {
        display: none !important;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .ant-drawer-body {
        -ms-overflow-style: none !important;
        scrollbar-width: none !important ;
    }
}

.filter_content_wrapper.listing_drawer:last-child {
    border-bottom: none;
}

.viewmenu_drawer {
    .ant-drawer-content-wrapper {
        width: 280px !important;
        margin-top: 107px;
        border-left: 0.5px solid #e3e3e3;

        box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1) !important;
    }
    .ant-drawer-header {
        display: none;
    }
    .ant-drawer-mask {
        background-color: transparent !important;
    }
    .ant-drawer-body {
        padding-top: 16px;
    }
    .filter__drawer {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 73vh;
        position: relative;
        @media only screen and (min-width: 768px) {
            height: 79vh;
        }
        @media only screen and (min-width: 1024px) {
            height: 86vh;
        }
        @media only screen and (min-width: 1440px) {
            height: 88.6vh;
        }
    }
    .menu_categoryitem {
        border-bottom: 1px solid #f1f1f1;
        color: #1d1d1d;
        // font-family: TT Norms Pro;
        font-size: 18px;
        font-weight: 600;
        line-height: 24px;
        padding: 16px 0;
        text-transform: capitalize;
        &:last-child {
            border: none;
        }
    }
}

.ant-notification-notice {
    width: auto;
    text-align: center;
    background-color: #beebfa !important;
    font-size: 12px;
    line-height: 16px;
    box-shadow: 0px 2px 11px rgba(0, 0, 0, 0.1);
    color: #fff !important;
    margin: auto;
    // position: absolute;
    padding: 8px !important;

    // right: 30% !important;

    // position: absolute !important;
    // top: -18px;
    // right: 30% !important;
    // width: auto;
    // max-width: 400px;
    // color: #fff !important;
    // box-shadow: 0px 2px 11px rgba(0, 0, 0, 0.1);
    // display: flex;
    // transition: all 350s ease-in-out;
    // font-size: 12px;
    // justify-content: center;
    // align-items: center;
    // margin: 0px;
    // padding-right: 0px;
    // min-width: 126px;
}

.ant-notification-notice-message {
    margin: 0px;
    padding: 0px !important;
    // width: auto;
}

.ant-notification-notice-close {
    display: none;
}
.cart_notification {
    max-width: 126px !important;
    .ant-notification-notice {
        position: absolute !important;
        top: 30px !important;
        right: 50% !important;
    }
}
