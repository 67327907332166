.ev_content {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.01em;
    color: #3f3f3f;
    margin-bottom: 16px;
}
.ev_content:nth-child(4) {
    margin-bottom: 24px;
}
.ev_content:last-child {
    margin-top: 24px;
}
