.navbar {
    background-color: $primaryColor !important;
    color: #ffffff;
    height: 56px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;
    position: fixed;
    width: 100%;
    z-index: 2;
    .navmenu {
        margin-top: 6px;
        cursor: pointer;
        z-index: 2;
        display: flex;
        align-content: center;
        justify-content: space-between;

        &_items {
            &_select {
                cursor: pointer;
                background-color: inherit;
                border: none;
                outline: none;
                -webkit-appearance: none;
                -moz-appearance: none;
                text-indent: 1px;
                // text-overflow: '';
                & > option {
                    color: black;
                }
            }
            // & > button {
            //     width: 2.5rem;
            //     height: 1.8rem;
            //     border: none;
            //     color: black;
            //     cursor: pointer;
            // }
            // & > button:first-child {
            //     border-radius: 25% 0 0 25%;
            // }
            // & > button:last-child {
            //     border-radius: 0 25% 25% 0;
            // }
        }
    }
    // .venue_name {
    //     z-index: 999 !important;
    //     width: 100%;
    //     position: absolute;
    //     left: 0;
    //     display: flex;
    //     justify-content: center;
    //     align-items: center;
    // }
}

.left-arrow__icon {
    color: #ffffff;
    font-size: 24px;
}

.menu__sidebar {
    .side_link {
        color: $primaryColor;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
    }
    .close-icon {
        position: absolute;
        top: 0px;
        right: 0px;
        font-size: 20px;
        padding: 16px;
    }
    .ant-drawer-content-wrapper {
        width: 78% !important;
        @media only screen and (min-width: 425px) {
            width: 60% !important;
        }
        @media only screen and (min-width: 560px) {
            width: 50% !important;
        }
        @media only screen and (min-width: 768px) {
            width: 34% !important;
        }
    }
    .ant-drawer {
        position: relative;
    }
    .ant-drawer-mask {
        width: 100%;
    }
    .ant-drawer-header {
        padding: 16px !important;
        display: none;
        font-weight: 700;
        font-size: 23px;
        line-height: 28px;
        color: $primaryColor;
    }
    .ant-drawer-close {
        position: absolute;
        top: 0px;
        right: 0px;
        z-index: 9;
    }
    .ant-drawer-header-title {
        .antiicon-close svg {
            color: #e21d1d !important;
        }
    }
    .ant-drawer-body {
        padding: 60px 22px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .drawer__menucontent {
        .menu-sidebar__img {
            width: 100px;
            // height: 22px;
            margin-bottom: 64px;
            // margin-top: 80px;
            img {
                width: 100%;
                height: 100%;
            }
        }
    }
    .sidebar__content {
        .content-II {
            font-weight: 400;
            font-size: 10px;
            line-height: 12px;
            color: #646268;
            margin-bottom: 18px;
        }
    }
    ul {
        padding: 0px;
        display: flex;
        flex-direction: column;
        gap: 30px;
        li {
            list-style: none;

            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
            color: $primaryColor;
        }
    }
    .menu__code {
        display: flex;
    }
    .menu__code span {
        font-family: "Lato", sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 12px;
        color: #646268;
    }
    .menu__code span::after {
        content: ".";
        font-size: 24px;
        margin: 0 4px;
    }
}

.nav-venuename {
    position: absolute;
    left: 0;
    width: 100%;
    font-size: 18px;
    display: flex;
    justify-content: center;
    color: $secondaryColor;
    text-transform: capitalize;
    z-index: 1;
    a {
        color: $secondaryColor;
        text-align: center;
    }
}

.dinein {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    text-align: right;
    color: #ffffff;
    text-transform: capitalize;
}

.left-arrow {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 2;
}
