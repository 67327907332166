.directory_detail {
    .directory_detailcover {
        height: 187px;
        position: relative;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        div {
            position: absolute;
            left: 16px;
            bottom: 16px;
            display: flex;
            flex-direction: column;
            gap: 2px;
            color: #fff;
            span {
                line-height: 140%;
                &:nth-child(1) {
                    font-size: 16px;
                    font-weight: 700;
                }
                &:nth-child(1) {
                    font-size: 15px;
                    font-weight: 400px;
                }
            }
        }
    }
    .detail_contentwrapper {
        padding: 20px;
        min-height: calc(100vh - 187px);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 20px;
        .detail_content {
            .detal_contdesc {
                font-size: 16px;
                font-weight: 400;
                line-height: 140%;
                margin-bottom: 20px;
                span {
                    color: #004ef9;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: normal;
                    margin-left: 4px;
                }
            }
            .detail_contentlists {
                .detail_listblock {
                    display: flex;
                    align-items: flex-start;
                    justify-content: flex-start;
                    gap: 30px;
                    border-bottom: 1px solid #dadada;
                    padding: 14px 0;
                    &:nth-child(1) {
                        border-top: 1px solid #dadada;
                    }
                    .icon {
                    }
                    .data {
                        display: flex;
                        flex-direction: column;
                        span {
                            &:nth-child(1) {
                                font-size: 15px;
                                font-weight: 500;
                            }
                            &:nth-child(2) {
                                font-size: 13px;
                                font-weight: 400;
                                color: #323232;
                            }
                        }
                    }
                }
            }
        }
    }
}
