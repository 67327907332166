.payment_container {
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
    padding-top: 70px;
    .payment__form {
        margin-bottom: 16px;
        h2 {
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            color: #000000;
            margin-bottom: 24px;
        }
        .payment__formgroup {
            display: flex;
            flex-direction: column;
            margin-bottom: 16px;
            label {
                font-weight: 500;
                font-size: 13px;
                line-height: 16px;
                color: #000000;
                margin-bottom: 8px;
            }
            input {
                padding: 12px;
                border-radius: 8px;
                border: 1px solid #646268;

                font-weight: 500;
                font-size: 14px;
                line-height: 16px;
                color: #000000;
                outline: none;
            }
            p {
                font-weight: 400;
                font-size: 11.9048px;
                line-height: 14px;
                color: #cc1818;
                margin-top: 8px;
                margin-bottom: 0px;
            }
        }
    }
    .paymentmethod_wrapper {
        margin-top: 28px;
        h3 {
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            color: #000000;
        }
        .payment_method {
            margin-top: 12px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 16px;
            width: 100%;
            &:hover {
                width: 100%;
                border: 1px solid #000000;
                border-radius: 8px;
            }
            .payment_category {
                display: flex;
                align-items: center;
                div {
                    background-color: #e2e2e2;
                    font-weight: 600;
                    font-size: 9px;
                    line-height: 11px;
                    color: #000000;
                    padding: 5px;
                    border-radius: 4px;
                }
                h6 {
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 15px;
                    color: #646268;
                    margin: 0px 0px 0px 8px;
                }
            }
        }
    }
}

.payment__btn {
    width: 100%;
    button {
        background-color: #000000;
        width: 100%;

        font-weight: 600;
        font-size: 22px;
        line-height: 27px;
        text-align: center;
        color: #ffffff;
        border-radius: 8px;
        border: none;
        padding: 16px;
    }
}
