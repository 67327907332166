.choosed-table_section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 14px;
    // box-shadow: inset 0px 0px 4px rgba(0, 0, 0, 0.1);
    border-bottom: 0.5px solid #e4e4e4;
    span {
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        color: $primaryColor;
    }
    .choosedtable__btn {
        padding: 6px 12px;
        background: #f1f1f1;
        border-radius: 87px;
        button {
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            text-align: center;
            justify-content: center;

            color: #646268;
            border: none;
        }
    }
}
