.Verification-page {
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media only screen and (min-width: 768px) {
        padding: 40px;
    }
    .mobileframe {
        width: 128px;
        height: 128px;
        background: #e9e9e9;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 40px;
        img {
            width: 50%;
            height: 100%;
        }
    }
    h1 {
        font-weight: 700;
        font-size: 24px;
        line-height: 29px;
        text-align: center;
        color: $primaryColor;
        margin: 0px;
    }
    p {
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        text-align: center;
        color: $primaryColor;
        margin-top: 10px;
        margin-bottom: 0px;
    }
    .intl-tel-input {
        width: 100%;
        margin-top: 29px;
        .form-control {
            background: #e9e9e9;
            border: 1px solid #f1f1f1;
            border-radius: 8px;
            height: 60px;
            border-radius: 8px;
            outline: none;
            width: 100%;
            .iti-flag {
                width: 80px;
                height: 40px !important;
            }
        }
    }
    .submit-btn {
        width: 100%;
        background: $primaryColor;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 16px;
        margin: 13px 0;
        button {
            font-weight: 600;
            font-size: 22px;
            line-height: 27px;
            text-align: center;
            color: #ffffff;
            background-color: $primaryColor;
            border: none;
        }
    }
    .otp-paragraph {
        font-weight: 400;
        font-size: 9.66495px;
        line-height: 12px;
        text-align: center;
        color: #646268;
    }
}
