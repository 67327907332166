.landingPackage_section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 60px 16px;
    .landing_headingII {
        font-family: $hellix;
        font-weight: 700;
        text-align: center;
        max-width: 600px;
        margin: auto;
    }
    .package_content {
        font-weight: 400;
        font-size: 20px;
        line-height: 28px;
        color: $primaryColor;
        text-align: center;
    }
    .landingpackage_wrapper {
        display: flex;
        flex-direction: column;
        gap: 40px;
        margin-top: 32px;
    }
}

@media only screen and (min-width: 860px) {
    .landingPackage_section {
        padding: 60px 40px;
        position: relative;
        .imageiconI {
            position: absolute;
        }
        .landing_headingII {
            max-width: 455px;
        }
        .landingpackage_wrapper {
            display: flex;
            flex-direction: row;
            gap: 30px;
        }
    }
}

@media only screen and (min-width: 1024px) {
    .landingPackage_section {
        padding: 120px 80px;
    }
}

@media only screen and (min-width: 1280px) {
    .landingPackage_section {
        padding: 120px 100px;
    }
}
