.readmore_linkdata {
    color: $primaryColor;
    display: flex;
    column-gap: 8px;
    align-items: center;
    font-weight: 600;
    font-size: 20px;
    line-height: 124.5%;
    letter-spacing: -0.01em;
    text-transform: capitalize;
}

.readmore_linkdata:hover {
    color: $primaryColor;
}