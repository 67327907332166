@mixin regular-font {
    font-family: $fontfam-1;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    color: $primaryColor;
}
@mixin bold-font {
    font-family: $fontfam-1;
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 140%;
    line-height: 140%;
    color: $primaryColor;
}
@mixin light-font {
    font-family: $fontfam-1;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 140%;
    color: $gray-3;
}
@mixin extralight-font {
    font-family: $fontfam-1;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    color: $primaryColor;
}

@mixin btn-font {
    font-family: $font-fam;
    font-size: 18px;
    font-weight: 500;
    line-height: 140%;
    padding: 14px 16px;
}
@mixin small-font {
    font-family: $fontfam-1;
    font-size: 12px;
    font-weight: 500;
    line-height: 140%;
}
@mixin medium-font {
    font-size: 16px;
    line-height: 140%;
}
