* {
    margin: 0;
    padding: 0;
}
.form_wrapper {
    background-color: #fafafa;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    @media only screen and (min-width: 768px) {
        position: relative;
        background-color: #f2f2f2;
    }

    .landingPackage_section {
        padding-top: 64px !important;
        .landing-package {
            background-color: #fafafa;
        }
        .landing_headingII {
            font-weight: 600;
            font-size: 31px;
            line-height: 43px;
            margin-bottom: 4px !important;
        }
    }
    .form_container {
        border-radius: 20px;
        width: 415px;
        padding: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        @media only screen and (min-width: 768px) {
            padding: 40px;
            border: 1px solid #000;
            background-color: #fff;
        }
        .vemail_content {
            font-weight: 400;
            font-size: 20px;
            line-height: 28px;
            text-align: center;
            color: #595959;
        }
        .form_logo {
            width: 110px;
            margin-bottom: 16px;
            img {
                width: 100%;
                height: 100%;
            }
        }
        .form_heading {
            @include bold-font;
            margin-bottom: 6px;
            text-align: center;
        }
        .verify_content {
            margin-bottom: 32px;
            font-family: $fontfam-1;
        }
        h1 {
            font-weight: 700;
            font-size: 31px;
            line-height: 44px;
            margin-bottom: 16px !important;
            text-align: center;
        }
        h2 {
            font-weight: 600;
            font-size: 25px;
            line-height: 35px;
        }
        h3 {
            font-weight: 600;
            font-size: 20px;
            line-height: 28px;
        }
        .about-subheading {
            font-family: $fontfam-1;
            font-weight: 400;
            font-size: 13px;
            line-height: 18px;
            color: #595959;
            text-align: center;
            margin-bottom: 0px;
        }
        .form_subheading {
            @include extralight-font;
        }
        .form_subheading span {
            font-weight: 600;
            @include extralight-font;
        }
        .login-acc {
            color: #1890ff !important;
            @include extralight-font;
        }
        .signup_form,
        .login_form {
            width: 100%;
            margin-top: 40px;

            .form_inputfield {
                width: 100%;
                margin-bottom: 16px;
                position: relative;
                &:last-child {
                    margin-bottom: 0px;
                }
                .signup_smselect {
                    position: absolute;
                    width: auto !important;
                    select {
                        -webkit-appearance: none;
                        -moz-appearance: none;
                        text-indent: 1px;
                        text-overflow: "";
                    }
                    .select_input {
                        height: 54px !important;
                        border-top-right-radius: 0px;
                        border-bottom-right-radius: 0px;
                    }
                }
                .related_inputfield {
                    padding-left: 68px;
                }
                .select_input,
                input {
                    font-family: $fontfam-1;
                    width: 100%;
                    border: 1px solid $primaryColor;
                    border-radius: 4px;
                    padding: 15px 8px;
                    outline: none;
                    &::placeholder {
                        @include light-font;
                    }
                }
                select option:not(:disabled) {
                    color: $primaryColor;
                }
                select:not(:checked) {
                    color: $gray-3;
                }
            }
            .form_if {
                margin-bottom: 40px;
            }
            .signup_checkcontent {
                @include regular-font;
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 8px;
                margin-bottom: 40px;
                .check_content {
                    display: flex;
                    align-items: center;
                    gap: 16px;
                }
                .detail__checkbox {
                    padding-left: 25px;
                    .checkmark {
                        width: 24px;
                        border-radius: 4px;
                    }
                }

                a {
                    margin-left: 3px;
                }
            }
            .signup_togglebutton {
                background-color: $primaryColor;
                color: $secondaryColor;
                padding: 14px;
                position: absolute;
                right: 0px;
                top: 0px;
                border-radius: 0px 4px 4px 0px;
                border: none;
                width: 100%;
                height: 54px;
                width: 50px;
                padding-top: 16px;
            }
        }

        .btn-black-outlined {
            width: 100%;
            margin-top: 16px;
        }
    }
    .vemail_container {
        width: auto;
        h1 {
            margin-bottom: 24px !important;
        }
        .vemail_content {
            font-family: $fontfam-1;
            margin-bottom: 16px !important;
        }
    }
}
.about_user,
.about_company {
    .form_heading {
        margin-top: 40px;
        @media only screen and (min-width: 768px) {
            margin-top: 0px;
        }
    }
    .btn-lgbutton {
        margin-top: 16px;
    }
}
.about_company {
    .btn-lgbutton {
        margin-top: 16px !important;
    }
}
.aboutbusiness_container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f2f2f2;
    min-height: 100vh;
    @media only screen and (min-width: 990px) {
        padding: 30px 0;
    }

    .aboutbusiness_innerwidth {
        display: flex;
        flex-direction: column-reverse;
        max-width: 1000px;
        @media only screen and (min-width: 768px) {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: row;
        }

        .aboutbusiness_leftcontaner {
            position: relative;
            .ab_content {
                padding: 16px;
                @media only screen and (min-width: 768px) {
                    padding: 40px 40px 0px 40px;
                }
            }

            @media only screen and (min-width: 768px) {
                width: 50%;
                height: 100vh;
                overflow: scroll;
                background-color: #fff;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: space-between;
                -ms-overflow-style: none;
                scrollbar-width: none;
            }
            @media only screen and (min-width: 990px) {
                border-top-left-radius: 20px;
                border-bottom-left-radius: 20px;
                height: 500px;
            }

            &::-webkit-scrollbar {
                display: none;
            }
            .form_logo {
                width: 136px;
            }
            .form_heading {
                font-family: $fontfam-1;
                font-weight: 700;
                font-size: 25px;
                line-height: 35px;
                color: $primaryColor;
                margin-top: 20px;
            }
            .about-subheading {
                @include regular-font;
            }
            .aboutbusiness_tabheading {
                margin-top: 32px;
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;
                color: $primaryColor;
                margin-bottom: 16px;
                font-family: $fontfam-1;
            }
            .aboutbusiness_tabswrapper {
                display: flex;
                flex-wrap: wrap;
                column-gap: 10px;
                row-gap: 16px;
                margin-bottom: 24px;
                .aboutbusiness_tabs {
                    display: flex;
                    align-items: center;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                    color: $primaryColor;
                    gap: 10px;
                    border: 1px solid #b3b3b3;
                    border-radius: 20px;
                    padding: 8px;
                    font-family: $fontfam-1;
                    cursor: pointer;
                    div {
                        display: flex;
                        gap: 8px;
                    }
                }
                .tabactive {
                    background-color: $primaryColor;
                    color: $secondaryColor;
                }
            }
            .aboutbusiness_btn {
                padding: 16px;

                border: none;

                .btn-lgbutton {
                    width: 286px;
                    font-family: $fontfam-1;
                    @media only screen and (min-width: 768px) {
                        width: 100% !important;
                    }
                }
                @media only screen and (min-width: 768px) {
                    width: 100% !important;
                    padding: 16px 40px 40px 40px;
                    position: sticky;
                    bottom: 0px;
                    background-color: $secondaryColor;
                }
            }
        }
        .aboutbusiness_rightcontaner {
            display: none;
            width: 400px;

            @media only screen and (min-width: 768px) {
                display: flex;
                height: 100vh;
            }
            @media only screen and (min-width: 990px) {
                height: 500px !important;
            }

            img {
                width: 100%;
                object-fit: cover;
                @media only screen and (min-width: 990px) {
                    border-top-right-radius: 20px;
                    border-bottom-right-radius: 20px;
                }
            }
        }
    }
}
.side_logo {
    position: absolute;
    left: calc(50% - 40px);
    top: 20px;
    width: 80px;
    height: 46px;
    @media only screen and (min-width: 768px) {
        left: 40px;
        top: 40px;
    }

    img {
        width: 100%;
    }
}
.forget_password {
    text-decoration: underline;
    @include extralight-font;
    margin-bottom: 40px;
    cursor: pointer;
}
.form_backbtn button,
.signup_btn button,
.login_btn button {
    font-family: $fontfam-1;
    font-size: 16px;
}
.signup_createbtn button {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
}
.vemail_container {
    .vemail_content {
        &:last-child {
            margin-bottom: 0px !important;
        }
    }
}
.socialmedia-button {
    margin-top: 16px;
    .continuegoogle {
        font-family: $fontfam-1;
        cursor: pointer;
        width: 100%;
        padding: 12px;
        border-radius: 8px;
        border: 1px solid #212121;
        background-color: #ffffff;
        display: flex;
        gap: 10px;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        font-weight: 500;
        line-height: 140%;
    }
}
.login_form {
    .signup_createbtn {
        .button {
            margin-top: 30px;
        }
    }
}
